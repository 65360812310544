


























































import { computed, defineComponent, PropType, ref, watch } from "@vue/composition-api";

import DateRangePicker from "@/app/components/Base/DateRangePicker.vue";
import FilterModal from "@/app/components/Base/FilterModal.vue";
import VSelect from "@/app/components/Base/VSelect.vue";
import { DEFAULT_FILTER_DATA } from "@/shared/constants/components/Project/ProjectFilter";
import { ModifiedProjectFilter, ProjectPriority, ProjectStatus } from "@/shared/types/components/Project/Project";
import { makeComboOptions } from "@/shared/utils";

export default defineComponent({
  name: "ProjectFilter",
  components: {
    DateRangePicker,
    VSelect,
    FilterModal,
  },
  props: {
    value: {
      type: Object as PropType<ModifiedProjectFilter>,
      default: DEFAULT_FILTER_DATA, 
    },
    priorities: {
      type: Array as PropType<ProjectPriority[]>,
      default: () => [],
    },
    projectStatuses: {
      type: Array as PropType<ProjectStatus[]>,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const projectFilter = ref<ModifiedProjectFilter>(_.cloneDeep(DEFAULT_FILTER_DATA));
    const filterModalIsShown = ref(false);

    watch(
      () => props.value as ModifiedProjectFilter,
      (val) => {
        if (!val) return;
        projectFilter.value = _.cloneDeep(val);
      },
    );

    const searchByText = _.debounce(() => {
      emit("input", projectFilter.value);
    }, 200);

    const projectPrioritiesOptions = computed(() => {
      if (!props.priorities) return [];
      return makeComboOptions(props.priorities, "code", "name");
    });

    const projectStatusesOptions = computed(() => {
      if (!props.priorities) return [];
      return makeComboOptions(props.projectStatuses, "code", "name");
    });


    const changeRangePicker = (ev: number[]) => {
      if (!projectFilter.value.deadlineInterval) return;
      projectFilter.value.deadlineInterval.begin = ev[0];
      projectFilter.value.deadlineInterval.end = ev[1];
    };

    const changeSelect = (k: "priorityCode" | "statusCode", ev: string | null) => {
      if (!projectFilter?.value) return;
      projectFilter.value = {
        ...projectFilter.value,
        [k]: ev || null, 
      };
    };

    const clearFilter = () => {
      emit("input", _.cloneDeep(DEFAULT_FILTER_DATA));
    };

    const applyFilter = () => {
      filterModalIsShown.value = false;
      emit("input", projectFilter.value);
    };

    function showFilterModal() {
      projectFilter.value = _.cloneDeep(props.value);
      filterModalIsShown.value = true;
    }

    return {
      projectFilter,
      searchByText,
      projectPrioritiesOptions,
      projectStatusesOptions,
      changeRangePicker,
      changeSelect,
      showFilterModal,
      filterModalIsShown,
      clearFilter,
      applyFilter,
    };
  },
});
