
















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "BackLink",
  props: {
    to: {
      type: String,
      default: '/',
    },
  },
});
