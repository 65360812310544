import { ModifiedProjectFilter } from "@/shared/types/components/Project/Project";

export const DEFAULT_FILTER_DATA: ModifiedProjectFilter = {
  chars: null,
  priorityCode: null,
  statusCode: null,
  deadlineInterval: {
    begin: undefined,
    end: undefined,
  },
  isArchived: false,
};
