















































import { useQuery, useResult } from "@vue/apollo-composable";
import { computed, defineComponent, ref } from "@vue/composition-api";

import BackLink from "@/app/components/Base/BackLink.vue";
import Pagination from "@/app/components/Base/Pagination.vue";
import ProjectFilter from "@/app/components/Project/ProjectFilter.vue";
import ProjectList from "@/app/components/Project/ProjectList.vue";
import TaskNavigation from "@/app/components/Task/TaskNavigation.vue";
import { PROJECT_ROUTES } from "@/shared/constants";
import { DEFAULT_FILTER_DATA } from "@/shared/constants/components/Project/ProjectFilter";
import { usePriorities, useProjectStatuses } from "@/shared/hooks/fetchers";
import { usePagination } from "@/shared/hooks/pagination";
import { BaseProjectInfo, ModifiedProjectFilter } from "@/shared/types/components/Project/Project";
import { ProjectsDocument } from "@/shared/types/schema.types";
import { getFilterQuery } from "@/shared/utils";

export default defineComponent({
  name: "project-page",
  components: {
    ProjectList,
    ProjectFilter,
    Pagination,
    TaskNavigation,
    BackLink,
  },

  setup() {
    const { pagination } = usePagination();
    const filterData = ref<ModifiedProjectFilter>(_.cloneDeep(DEFAULT_FILTER_DATA));

    const filterQuery = computed(() => getFilterQuery(filterData.value));

    const { result, loading, refetch: refetchProjects } = useQuery(ProjectsDocument, () => {
      if (!filterQuery) return { projectFilter: undefined };
      return {
        projectFilter: filterQuery.value,
        pagination: pagination.value,
        isArchiveTasks: false,
      };
    });
    const projects = useResult(result, [], (data) =>
      data.projectSheet.projects.sort((a, b) => ~~b.pinned - ~~a.pinned),
    );
    const total = useResult(result, 1, (data) => data.projectSheet.sheetInfo.totalPages);

    const filterProjects = async(projectFilter: ModifiedProjectFilter) => {
      filterData.value = projectFilter;
      pagination.value.pageNumber = 0;
    };

    const changePage = (page: number) => {
      pagination.value.pageNumber = page;
    };
    const { data: priorities } = usePriorities();
    const { data: projectStatuses } = useProjectStatuses();

    return {
      projects: projects,
      total: total,
      loading,
      filterData,
      filterProjects,
      pagination,
      priorities,
      projectStatuses,
      filterQuery,
      changePage,
      refetchProjects,
    };
  },

  methods: {
    handlerCardClick(card: BaseProjectInfo): void {
      if (!card) return;
      const route = PROJECT_ROUTES(card.id);
      this.$router.push(route.BASE);
    },
  },
});
