































import { defineComponent, PropType } from "@vue/composition-api";

import NoData from "@/app/components/Base/NoData.vue";
import ProjectCard from "@/app/components/Project/ProjectCard.vue";
import { PROJECT_ROUTES } from "@/shared/constants";
import { useRouter } from "@/shared/hooks";
import { useNotification } from "@/shared/hooks/notification";
import { archiveProject, togglePinProject, unzipProject } from "@/shared/requests";
import { BaseProjectInfo } from "@/shared/types/components/Project/Project";
import { copyTextToClipboard } from "@/shared/utils";

export default defineComponent({
  name: "ProjectList",
  components: {
    NoData,
    ProjectCard,
  },
  props: {
    projects: {
      type: Array as PropType<BaseProjectInfo[]>,
      default: () => [],
    },
  },
  setup(_, { emit }) {
    const { errNotification, successNotification } = useNotification();

    const router = useRouter();

    function handlerCardClick(card: BaseProjectInfo): void {
      this.$emit("card-click", card);
    }

    function handlerEdit(project: BaseProjectInfo): void {
      const route = PROJECT_ROUTES(project.id).EDIT_PROJECT;
      router.push(route);
    }

    function handlerToNewProject(): void {
      router.push("/projects/new-project");
    }

    function handleCopy(project: BaseProjectInfo) {
      copyTextToClipboard(`${window.location.origin}/projects/${project.id}`, () => successNotification('Ссылка на проект успешно скопирована в буфер'), (text: string) => errNotification(text));
    }

    const handlerPin = async(project: BaseProjectInfo): Promise<void> => {
      try {
        await togglePinProject({ projectId: project.id });
        successNotification(`Проект ${project.name} ${ project.pinned? 'откреплен' : 'прикреплен' }`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    };

    const handleZip = async(project: BaseProjectInfo): Promise<void> => {
      try {
        await archiveProject({ id: project.id });
        successNotification(`Проект ${project.name} перемещен в архив`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    };

    const handleUnzip = async(project: BaseProjectInfo): Promise<void> => {
      try {
        await unzipProject({ id: project.id });
        successNotification(`Проект ${project.name} восстановлен из архива`);
        emit('refetch-data');
      }
      catch(err) {
        console.error(err);
        errNotification(err);
      }
    };

    return {
      handlerPin,
      handlerEdit,
      handleCopy,
      handleZip,
      handlerCardClick,
      handlerToNewProject,
      handleUnzip,
    };
  },
});
