




























































































import { computed, defineComponent, PropType } from "@vue/composition-api";

import Priority from "@/app/components/Base/Priority.vue";
import TaskTableTooltip from "@/app/components/Task/TaskTable/TaskTableTooltip.vue";
import { BaseProjectInfo } from "@/shared/types/components/Project/Project";
import { formatDateTimeForDisplay } from "@/shared/utils";

export default defineComponent({
  name: "ProjectCard",
  components: {
    Priority,
    TaskTableTooltip, 
  },
  props: {
    project: {
      type: Object as PropType<BaseProjectInfo>,
      required: true,
    },
  },
  setup(props, { emit }) {
    function handleClick(): void {
      emit("on-click", props.project);
    }
    function handlePin(): void {
      emit("on-pin", props.project);
    }
    function handleEdit(): void {
      emit("on-edit", props.project);
    }
    function handleCopy(): void {
      emit("on-copy", props.project);
    }
    function handleZip(): void {
      emit("on-zip", props.project);
    }
    function handleUnzip(): void {
      emit("on-unzip", props.project);
    }
    const projectDeadline = computed<string>(() => {
      return formatDateTimeForDisplay(props.project?.deadline);
    });

    return {
      handlePin,
      handleEdit,
      handleCopy,
      handleZip,
      handleClick,
      handleUnzip,
      projectDeadline,
    };
  },
});
